import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BsCheck2 } from 'react-icons/bs'

const ListItem = ({ name, handleFilters, isReseted, setIsReseted }) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (isReseted) {
      setIsChecked(false)
    }
  }, [isReseted])

  const handleClick = (name) => {
    setIsReseted(false)
    handleFilters(name)
    setIsChecked(!isChecked);
  };

	return (
		<li
			onClick={() => handleClick(name)}
			className="border-b border-black pb-2 cursor-pointer flex w-full justify-between items-center"
		>
      <span
      dangerouslySetInnerHTML={{__html: name }}
      />
      {isChecked && <BsCheck2 className='text-lg text-green-accent' />}
		</li>
	)
}

ListItem.propTypes = {
	hide: PropTypes.bool,
}

export default ListItem
