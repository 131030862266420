import React from 'react';

const Loader = () => {
  return (
    <div className='relative w-full h-full flex flex-col justify-center items-center'>
      <div className="loader"></div>
      <div className='text-6xl z-30'>läser in...</div>
    </div>
  );
};

export default Loader;
